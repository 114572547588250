import { CircularProgress } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import SelectComponent from '../../../../components/ui/select-component';
import { useEffect, useState } from 'react';
import submissionService from '../../../../resources/submission/submission.service';
import * as submissionReducer from '../../../../redux/reducers/submissionReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { SubmissionDetailsDTO } from '../../../../interface/submission-details-dto';
import submissionOptionsService from '../../../../resources/submission-options/submission-options.service';
import AlertComponent from '../../../../components/ui/alert';
import { Assignee } from '../../../../interface/assignee';
import { WorkflowStatus } from '../../../../interface/workflow-status';
import * as summaryReducer from '../../../../redux/reducers/summaryReducer';
import { SummaryDetail } from '../../../../interface/summary-detail';
import { IOption } from '../../../../components/ui/multi-select-dropdown';

export default function DetailsEditModal() {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [allStatusOptions, setAllStatusOptions] = useState<Array<IOption>>([]);
  const [selectedStatus, setSelectedStatus] = useState<IOption>({ value: '', label: '' });
  const [allUsersOptions, setAllUsersOptions] = useState<Array<IOption>>([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<IOption>({ value: '', label: '' });
  const [updateDetailsErrorMessage, setUpdateDetailsErrorMessage] = useState<string>('');

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { showDetailsEditModal, submissionDetails } = useAppSelector(state => state.summary);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchStatus();
    fetchAssignees();
  }, []);

  function handleAssignedTo(option: IOption) {
    setSelectedAssignedTo(option);
  }

  function handleStatus(option: IOption) {
    setSelectedStatus(option);
  }

  function closeEditDetailsAlert() {
    setUpdateDetailsErrorMessage('');
  }

  const handleSubmmitModal = async (e: any) => {
    setUpdateDetailsErrorMessage('');

    if (
      currentRecord.workflowStatus === selectedStatus.label &&
      (currentRecord.assignedToName === selectedAssignedTo.label ||
        (currentRecord.assignedToName === null && selectedAssignedTo.label === ''))
    ) {
      setUpdateDetailsErrorMessage(
        'To proceed with the update, please choose different values for at least one of the fields.',
      );
      return;
    }

    if (isRequesting) {
      return;
    }

    setIsRequesting(true);

    submissionService
      .updateSubmissionDetails(currentRecord.submissionId, selectedAssignedTo.value, selectedStatus.value)
      .promise.then(() => {
        dispatch(summaryReducer.setShowDetailsEditModal(false));
        dispatch(
          submissionReducer.updateSummaryDetails({
            workflowStatus: selectedStatus.label,
            assignedToName: selectedAssignedTo.label,
          }),
        );

        const updatedSubmissionDetails = submissionDetails.map((detail: SummaryDetail) => {
          if (detail.title === 'Assigned to:') {
            return {
              ...detail,
              value: selectedAssignedTo.label,
            };
          }
          if (detail.title === 'Status:') {
            return {
              ...detail,
              value: selectedStatus.label,
            };
          }
          return detail;
        });

        dispatch(summaryReducer.setSubmissionDetails(updatedSubmissionDetails));
      })
      .catch((error: Error) => {
        setUpdateDetailsErrorMessage(
          "We couldn't update submissions details at the moment. Please try again in a few minutes.",
        );
        console.error(error.message);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  function fetchStatus() {
    submissionOptionsService.getAllStatus().promise.then((statuses: Array<WorkflowStatus>) => {
      const statusOptions: Array<IOption> = statuses.map((status: WorkflowStatus) => ({
        value: status.id.toString(),
        label: status.status,
      }));

      const sortedStatusOptions = statusOptions.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
      );
      setAllStatusOptions(sortedStatusOptions);

      let currentStatus = statusOptions.find(
        (statusOption: IOption) => statusOption.label === currentRecord.workflowStatus,
      );

      if (currentStatus) {
        setSelectedStatus(currentStatus);
      }
    });
  }

  function fetchAssignees() {
    submissionOptionsService.getAllAssignedsAvailable().promise.then((assignees: Array<Assignee>) => {
      const assigneeOptions: Array<IOption> = assignees.map((assignee: Assignee) => ({
        value: assignee.id.toString(),
        label: assignee.name,
      }));

      const sortedAssigneeOptions = assigneeOptions.sort((a, b) =>
        a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }),
      );
      setAllUsersOptions(sortedAssigneeOptions);

      let currentAssignee = assigneeOptions.find(
        (assigneeOption: IOption) => assigneeOption.label === currentRecord.assignedToName,
      );

      if (currentAssignee) {
        setSelectedAssignedTo(currentAssignee);
      }
    });
  }

  function onClose() {
    dispatch(summaryReducer.setShowDetailsEditModal(false));
    setUpdateDetailsErrorMessage('');
  }

  return (
    <Modal
      className="summaryCustomModal"
      size="sm"
      show={showDetailsEditModal}
      onHide={() => onClose()}
      aria-labelledby="EditDetailsModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="EditDetailsModal">
          Edit Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        {updateDetailsErrorMessage && (
          <AlertComponent
            type="danger"
            text={updateDetailsErrorMessage}
            buttonText="X"
            onClick={closeEditDetailsAlert}
          />
        )}
        {isRequesting ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '32px',
            }}>
            <CircularProgress color="inherit" size={40} />
          </div>
        ) : (
          <div className="bodyContainer">
            <p>Select the new assigned and status:</p>
            <SelectComponent
              label="Assigned to"
              name="assigned-to"
              onChange={handleAssignedTo}
              isDisabled={false}
              options={allUsersOptions ?? []}
              selectedOption={selectedAssignedTo}
            />
            <SelectComponent
              label="Status"
              name="status"
              onChange={handleStatus}
              isDisabled={false}
              options={allStatusOptions ?? []}
              selectedOption={selectedStatus}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="button submitButton" onClick={handleSubmmitModal}>
          Confirm Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
