import { Paper, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';
import './index.scss';
import moment from 'moment';
import { formatDate } from '../../../helpers/date-helper';
import { ReactNode, useEffect, useState } from 'react';
import { SubmissionHistoryDTO } from '../../../interface/submission-history-dto';
import submissionHistoryService from '../../../resources/submission-history/submission-history.service';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import * as submissionHistoryReducer from '../../../redux/reducers/submissionHistoryReducer';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import LoadingScreen from '../../loading';

interface HistroryRow {
  date: string;
  user: string;
  action: string;
  details: string | ReactNode;
}

const submissionHistoryType = {
  SUBMISSION_INITIATED: 'Submission Initiated',
  STATUS_CHANGED: 'Status Changed',
  ASSIGNED_TO_CHANGED: 'Assigned To Changed',
  ISSUE_CREATED: 'Issue Created',
  ISSUE_AGREED: 'Issue Agreed',
  TRANSCRIPT_EDITED: 'Transcript Edited',
  REVIEW_SUBMITTED: 'Review Submitted',
  ATTACHMENT_ADDED: 'Attachment Added',
};

export default function HistoryTab() {
  const [rows, setRows] = useState<HistroryRow[]>([]);
  const [isRequesting, setIsRequesting] = useState<boolean>();
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const history: SubmissionHistoryDTO[] = useAppSelector(state => state.submissionHistory.history);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    buildRows();
  }, [history]);

  function fetchHistory() {
    setIsRequesting(true);

    submissionHistoryService
      .getHistory(currentRecord.submissionId)
      .promise.then((submissionHistory: SubmissionHistoryDTO[]) => {
        dispatch(submissionHistoryReducer.setHistory(submissionHistory));
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  function buildRows() {
    const buildedRows: HistroryRow[] = [];

    history.forEach((hist: SubmissionHistoryDTO) => {
      buildedRows.push(createData(hist.date, hist.user, hist.action, hist.details));
    });

    setRows(buildedRows);
  }

  function createData(date: Date, user: string, action: string, details: string): HistroryRow {
    return {
      date: formatDate(moment(date), 'MM-DD-YYYY hh:mm a'),
      user,
      action,
      details: buildDetails(action, details),
    };
  }

  function buildDetails(action: string, details: string): string | ReactNode {
    if (action === submissionHistoryType.REVIEW_SUBMITTED) {
      return (
        <a href="#" onClick={goToReviewHistory}>
          See this review
        </a>
      );
    }
    if (action === submissionHistoryType.ATTACHMENT_ADDED) {
      const attachmentObj = JSON.parse(details);

      return (
        <a href={attachmentObj.downloadLink} target="_blank" download={true}>
          {attachmentObj.fileName}
        </a>
      );
    }

    return details ? details : '-';
  }

  function goToReviewHistory() {
    dispatch(submissionReducer.changeCurrentTab({ id: 4, name: 'Review History', show: true }));
  }

  return (
    <Paper className="history-tab-container contentCard" variant="outlined">
      <div className="paper-title">
        <span className="paperText">History</span>
      </div>
      <div className="history-tab-content">
        {isRequesting ? (
          <LoadingScreen styles={{ width: '100%', height: '60vh' }} />
        ) : (
          <TableContainer className="history-tab-table-container">
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell width="200px">Date</TableCell>
                  <TableCell width="200px">User</TableCell>
                  <TableCell width="180px">Action</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell scope="row">{row.date}</TableCell>
                    <TableCell>{row.user}</TableCell>
                    <TableCell>{row.action}</TableCell>
                    <TableCell>{row.details}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Paper>
  );
}
